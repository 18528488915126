import React from "react";
import theme from "theme";
import { Theme, Em, Text, Link, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Apex Auto Enclave
			</title>
			<meta name={"description"} content={"Відкрийте для себе Apex Auto Enclave, найкраще місце для любителів екзотики та тюнінгу автомобілів"} />
			<meta property={"og:title"} content={"Apex Auto Enclave"} />
			<meta property={"og:description"} content={"Відкрийте для себе Apex Auto Enclave, найкраще місце для любителів екзотики та тюнінгу автомобілів"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-3.jpg?v=2024-05-30T11:22:30.190Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Section background="--color-dark" padding="80px 0 0px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					width="100%"
				>
					<Em>
						Контактна інформація
					</Em>
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Ми запрошуємо вас зв’язатися з нами або відвідати нас особисто, щоб ознайомитися з усіма нашими пропозиціями. Незалежно від того, чи хочете ви оновити свій автомобіль чи спілкуєтеся з іншими автолюбителями, Apex тут, щоб покращити вашу автомобільну подорож.
				</Text>
				<Link
					href="tel:+380675645682"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					0984386862
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					Naberezhna Peremohy St, 2Д,{" "}
					<br />
					Dnipro, Dnipropetrovsk Oblast, 49000
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					object-fit="cover"
					lg-max-height="230px"
					src="https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-4.jpg?v=2024-05-30T11:22:30.199Z"
					max-height="400px"
					width="100%"
					object-position="top"
					height="100%"
					srcSet="https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-4.jpg?v=2024-05-30T11%3A22%3A30.199Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-4.jpg?v=2024-05-30T11%3A22%3A30.199Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-4.jpg?v=2024-05-30T11%3A22%3A30.199Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-4.jpg?v=2024-05-30T11%3A22%3A30.199Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-4.jpg?v=2024-05-30T11%3A22%3A30.199Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-4.jpg?v=2024-05-30T11%3A22%3A30.199Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-4.jpg?v=2024-05-30T11%3A22%3A30.199Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Image
					src="https://uploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-1.jpg?v=2024-05-30T11:22:30.187Z"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					height="100%"
					srcSet="https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-1.jpg?v=2024-05-30T11%3A22%3A30.187Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-1.jpg?v=2024-05-30T11%3A22%3A30.187Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-1.jpg?v=2024-05-30T11%3A22%3A30.187Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-1.jpg?v=2024-05-30T11%3A22%3A30.187Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-1.jpg?v=2024-05-30T11%3A22%3A30.187Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-1.jpg?v=2024-05-30T11%3A22%3A30.187Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66583b218d4a0c00202fc4dc/images/1-1.jpg?v=2024-05-30T11%3A22%3A30.187Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text1">
				0984386862
				<br />
				<br />
				Naberezhna Peremohy St, 2Д,{" "}
				<br />
				Dnipro, Dnipropetrovsk Oblast, 49000
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});